import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import Games from '../components/Screens/Games';

// ====

const GamesPage = (props) => {
  const { data } = props;

  let doc;
  if (data) {
    doc = JSON.parse(data.index.content);
  }

  return (
    <Layout meta={doc.meta}>
      <Games
        title={doc.title}
        copy={doc.body}
        image={doc.image}
        items={doc.games}
        id="games"
        layout="natural-full"
      />
    </Layout>
  );
};

export default GamesPage;

// ====

export const QUERY = graphql`
  query GamesDefaultQuery {
    index: storyblokEntry(slug: { eq: "games" }, lang: { eq: "default" }) {
      content
    }
  }
`;
